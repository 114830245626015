<template>
    <div class="main-content mt-6">
        <div class="container lfont text-center">
            <v-row>
                <v-col cols="12" md="4" lg="4"></v-col>
                <v-col cols="12" md="4" lg="4">
                    <h1 class="text-primary">ກະລຸນາຢືນຢັນຂໍ້ມູນເພື່ອຢືນຢັນການສຳພາດ</h1>
                    <v-text-field label="ເບີໂທລະສັບ" type="number" v-model="phone_number"
                        @keyup.enter="confirm_identity"></v-text-field>
                    <v-btn class="ma-2 text-white lfont" color="primary" @click="confirm_identity">
                        ຢືນຢັນ
                    </v-btn>
                </v-col>
                <v-col cols="12" md="4" lg="4"></v-col>
            </v-row>

        </div>
        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import Loading from "@/components/Loading";
export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            apply_job_id: this.$route.params.id,
            phone_number: "",
            server_errors: {
                apply_job_id: "",
                phone_number: ""
            }
        }
    },
    methods: {

        confirm_identity() {
            this.isLoading = true;
            this.$axios
                .get("applicants/check/confirm/interview", {
                    params: {
                        apply_job_id: this.apply_job_id,
                        phone_number: this.phone_number
                    }
                })
                .then((res) => {
                    this.isLoading = false;
                    this.$router
                        .push({
                            name: "confirm.interview",
                            query: {
                                apply_job_id: this.apply_job_id,
                                phone_number: this.phone_number,
                            },
                        })
                        .catch(() => { });
                })
                .catch((error) => {
                    this.isLoading = false;
                    let obj = error.response.data.errors;
                    if (error.response.status === 422) {
                        for (let [key, value] of Object.entries(obj)) {
                            this.server_errors[key] = value[0];
                            this.$notification.ShowErrors(
                                "top-right",
                                "danger",
                                3000,
                                (this.server_errors[key] = value[0])
                            );
                        }
                    } else if (error.response.status === 404) {
                        console.log(error.response);
                        this.$notification.ShowErrors("top-right", "danger", 3000, error.response.statusText);
                    }
                });
        }
    },
}
</script>

<style lang="scss" scoped></style>